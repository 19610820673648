import React, { useState, useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section, Columns } from "../components/Containers"
import { Text, Image } from "../components/Core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import Pagination from "../components/pagination"

import "../css/theme/blogs-query.scss"
import { stripHtml } from "../utils/utils"

const BlogsQueryPage = ({ pageContext, location, data }) => {
  const blogs = data.blogs.nodes
  const blogsPerPage = 6
  const primaryBlogs = blogs.slice(1, 7)
  const [currPage, setCurrPage] = useState(0)
  const { metaTitle, metaDescription, language } = pageContext
  const [currRange, setCurrRange] = useState([0, 2])
  const totalPages = Math.ceil(blogs.length / 7)
  const scrollRef = useRef(null)
  const page = data.allUniquePagesJson.nodes[0]

  const handlePrevPageClick = () => {
    if (currPage === 0) {
      return
    }
    if (currPage === currRange[0]) {
      setCurrRange([currRange[0] - 1, currRange[1] - 1])
    }
    setCurrPage(cp => cp - 1)
    scrollToTop()
  }

  const handleNextPageClick = () => {
    if (currPage === totalPages - 1) {
      return
    }
    if (currPage === currRange[1]) {
      setCurrRange([currRange[0] + 1, currRange[1] + 1])
    }
    setCurrPage(cp => cp + 1)
    scrollToTop()
  }

  const handlePageClick = pageNum => {
    setCurrPage(pageNum)
    scrollToTop()
  }

  const getPublishedDate = publishedDate => {
    return (
      <time className="blogs-query-time px-40--mobile">
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit"
        }).format(new Date(publishedDate))}
      </time>
    )
  }

  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: scrollRef.current,
        behavior: "smooth"
      })
    }
  }

  const removeStyleAttributes = bodyWithStyle => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      let tempElement = document.createElement("div")
      tempElement.innerHTML = bodyWithStyle
      let elementsWithStyle = tempElement.querySelectorAll("[style]")
      elementsWithStyle.forEach(element => {
        element.removeAttribute("style")
      })
      return tempElement.innerHTML
    }
    return bodyWithStyle
  }

  return (
    <Layout className="blogs-query" language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Section colorBack zeroBottom>
        <Columns sideColumnsSize={1} className="blogs-query__spacer">
          <h1>{page.heading}</h1>
        </Columns>
      </Section>

      <Section colorBack>
        <Section
          zeroTop
          className={`${
            currPage !== 0
              ? "blogs-query__hide-blog"
              : "blogs-query__latest-blog"
          }`}>
          <div className="columns" ref={scrollRef}>
            <div className="column is-1"></div>
            <div className="column">
              <Link
                title="Read the full article"
                to={`/${blogs[0].title}`}
                className="blogs-query__latest-blog-link clear-decoration">
                <div className="blogs-query__latest-blog-image">
                  <Image publicId={blogs[0].hero} />
                </div>
                <div className="blogs-query__latest-blog-content">
                  <div className="blogs-query__latest-blog-heading">
                    <h2>{blogs[0].heading}</h2>
                    {getPublishedDate(blogs[0].publishedDate)}
                  </div>
                  <Text
                    className="blogs-query__latest-blog-sub-heading"
                    text={stripHtml(blogs[0].body)
                      .substring(0, 400)
                      .replace("@", "")}
                  />
                  <div className="blogs-query__latest-blog-icon px-40--mobile">
                    <div>
                      <FontAwesomeIcon icon={faArrowRight} />{" "}
                    </div>
                    <span>READ MORE</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="column is-1"></div>
          </div>
        </Section>

        <Section
          zeroTop
          className={
            currPage === 0
              ? "blogs-query__primary-blogs"
              : "blogs-query__hide-blog"
          }>
          <div className="columns">
            <div className="column is-1"></div>
            <div className="column blogs-query__primary-blog-container">
              {primaryBlogs.map((blog, index) => {
                return (
                  <Link
                    key={`blog_${index}`}
                    title="Read the full article"
                    to={`/${blog.title}`}
                    className="blogs-query__primary-blog-link clear-decoration">
                    <button
                      aria-label="click to read more about the article"
                      className="blogs-query__primary-blog-button">
                      <Image
                        publicId={blog.hero}
                        wrapperClassName="blogs-query__primary-blog-img"
                      />
                      <div className="blogs-query__primary-blog-icon">
                        <div>
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </div>
                      </div>
                    </button>
                    <div className="blogs-query__primary-blog-content">
                      <div className="blogs-query__primary-blog-heading">
                        <h2>{blog.heading}</h2>
                        {getPublishedDate(blog.publishedDate)}
                      </div>
                      <Text
                        className="blogs-query__primary-blog-sub-heading"
                        text={stripHtml(blog.body)
                          .substring(0, 400)
                          .replace("@", "")}
                      />
                    </div>
                  </Link>
                )
              })}
            </div>
            <div className="column is-1"></div>
          </div>
        </Section>

        <Section
          zeroTop
          className={`${
            currPage === 0
              ? "blogs-query__hide-blog"
              : "blogs-query__secondary-blogs"
          }`}>
          <div className="columns">
            <div className="column is-1"></div>
            <div className="column blogs-query__secondary-blog-container">
              {blogs.map((blog, index) => {
                return (
                  <Link
                    key={`blog_${index}`}
                    title="Read the full article"
                    to={`/${blog.title}`}
                    className={`clear-decoration ${
                      index >= currPage * blogsPerPage + 1 &&
                      index <= currPage * blogsPerPage + blogsPerPage
                        ? "blogs-query__secondary-blog-link"
                        : "blogs-query__hide-blog"
                    }`}>
                    <Image
                      publicId={blog.hero}
                      wrapperClassName="blogs-query__secondary-blog-img"
                    />
                    <div className="blogs-query__secondary-blog-content">
                      <h2>{blog.heading}</h2>
                      {getPublishedDate(blog.publishedDate)}
                      <Text
                        className="blogs-query__secondary-blog-sub-heading"
                        text={stripHtml(blog.body)
                          .substring(0, 400)
                          .replace("@", "")}
                      />
                      <div className="single-blog__recommended-link bold--600 px-40--mobile">
                        <div>
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </div>
                        <span>READ MORE</span>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
            <div className="column is-1"></div>
          </div>
        </Section>

        <Pagination
          currRange={currRange}
          currPage={currPage}
          totalPages={totalPages}
          onPrevClick={handlePrevPageClick}
          onNextClick={handleNextPageClick}
          onPageClick={handlePageClick}
        />
      </Section>
    </Layout>
  )
}

export const blogQuery = graphql`
  query blogsQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
      }
    }
    blogs: allBlogsJson(
      sort: { fields: publishedDate }
      filter: { hasThisPage: { eq: true } }
    ) {
      nodes {
        title
        heading
        body
        images
        publishedDate
        dateModified
        hero
      }
    }
  }
`

export default BlogsQueryPage
